@charset "utf-8";
/* ===================================================================
CSS information

 file name  : common.css
 author     : Ability Consultant
 style info : 基本・共通設定
=================================================================== */
@import "settings";

/* -----------------------------------------------------------
	基本設定、リンクカラーなど
----------------------------------------------------------- */
html {
	font-size: small;
	line-height: 1.5;
}
body {
	color: $baseColor;
	background: #fff;
	-webkit-text-size-adjust: none;
	@include fsz($baseSize,15);
	.ie & { @include fsz($baseSize,13.4); }
	@include mq(sp) {
		@include fsz($baseSize - 1,13);
	}
	a {
		color: lighten($baseColor, 10%);
		&:link,
		&:visited,
		&:active {
			text-decoration: underline;
		}
		.mouse &:hover,
		.touch &.touchstart {
			text-decoration: none;
			color: lighten($baseColor, 20%);
		}
	}
	img {
		max-width: 100%;
		-webkit-touch-callout: none;
	}
}
body,pre,input,textarea,select {
	font-family: 游明朝,"Yu Mincho",YuMincho,"Hiragino Mincho ProN",HGS明朝E,メイリオ,Meiryo,serif;
}
input,select,textarea {
	font-size: 100%;
}

$f_ale: 'Alegreya', serif;
$f_jul: 'Julius Sans One', sans-serif;
$f_noto: 'Noto Serif JP', sans-serif;

@mixin fsz-cha($pc_fsz:30,$tab_fsz:30,$sp_fsz:17) {
	@include fsz($pc_fsz);
	@include mq(tab) {
		@include fsz($tab_fsz);
	}
	@include mq(sp) {
		@include fsz($sp_fsz);
	}
}


// ie8フォロー
html.ie8 {
	.view_tab, 
	.view_tab_sp, 
	.view_sp {
		display: none !important;
	}
	#page {
		min-width: $columnWidth * 1px !important;
		margin: auto;
		font-size: 100% !important;
	}
}

// .view_xxx
body {
	@include mq(pc) {
		.view_tab-sp,.view_tab {display: none !important;}
	}
	@include mq(pc_tab) {
		.view_sp {display: none !important;}
	}
	@include mq(tab_sp) {
		.view_pc {display: none !important;}
	}
	@include mq(sp) {
		.view_pc-tab,.view_tab {display: none !important;}
	}
}

// .mouseOver
.over {
	.mouse & {
		// @include transition(opacity 200ms ease-in);
		transition: opacity 200ms ease-in;
	}
	@include hover {
		opacity: 0.7;
	}
}

// border-box
* {
	box-sizing: border-box;
}


/* -----------------------------------------------------------
	#content
----------------------------------------------------------- */
#content {
	@include mq(pc_tab) {
		font-size: 100% !important;
	}
	@include mq(sp) {
		font-size: 90% !important;
	}
}


/* -----------------------------------------------------------
	#header
----------------------------------------------------------- */
#header {

}


/* -----------------------------------------------------------
	#contents
----------------------------------------------------------- */
#contents {

}


/* -----------------------------------------------------------
	#footer
----------------------------------------------------------- */
#footer {
	
}


/* -----------------------------------------------------------
	下層共通スタイル
----------------------------------------------------------- */
html.lower #contents {

}


/* -----------------------------------------------------------
	テーブル
----------------------------------------------------------- */
table.tbl_basic {

}
/* -----------------------------------------------------------
	ボタン
----------------------------------------------------------- */
.btn_basic {
	width: 300px;
	max-width: 100%;
	@include fsz(14);
	@include mq(sp) {
		margin: 0 auto;
	}
	a,>span {
		@include transition4s();
		text-align: center;
		display: block;
		border: 1px solid $c_blue;
		// @include animate;
		background: $c_blue;
		padding: 1em .2em;
		color: #fff;
		@include mq(pc) {
			&:hover {
				background: #fff;
				color: $c_blue !important;
				border: 1px solid $c_blue;
				span {
					&:after {
						@include transition4s();
						border-top-color: $c_blue;
						border-right-color: $c_blue;
						right: -1.3em;
					}
				}
			}
		}
		span {
			display: inline-block;
			position: relative;
			&:after {
				// @include animate;
				position: absolute;
				top: 0;
				bottom: 0;
				margin: auto;
				content: "";
				vertical-align: middle;
				right: -1em;
				width: 5px;
				height: 5px;
				border-top: 1px solid #fff;
				border-right: 1px solid #fff;
				-webkit-transform: rotate(45deg);
				transform: rotate(45deg);
			}
		}
	}
	&.black {
		a,>span {
			border: 1px solid #000;
			background: #000;
			@include mq(pc) {
				&:hover {
					background: transparent;
					color: #fff !important;
					border: 1px solid #fff;
					span {
						&:after {
							@include transition4s();
							border-top-color: #fff;
							border-right-color: #fff;
							right: -1.3em;
						}
					}
				}
			}
		}
	}
	&.transp {
		a,>span {
			border: 1px solid #fff;
			background: transparent;
			@include mq(pc) {
				&:hover {
					background: $c_blue;
					color: #fff !important;
					border: 1px solid $c_blue;
					span {
						&:after {
							@include transition4s();
							border-top-color: #fff;
							border-right-color: #fff;
							right: -1.3em;
						}
					}
				}
			}
		}
	}
}

.con_room {
	position: relative;
	@include cV(margin, 0px, 3em 0px, 3em 0px);
	h2 {
		@include mq(tab) {
			top: 20%;
		}
		@include mq(sp) {
			top: 20%;
			color: #fff !important;
			border-color: #fff !important;
		}
	}
	.box_room {
		font-family: $f_noto;
		h2 {
			@include cV(padding-left, 198px, 80px, 1em);
		}
		.box_title {
			@include cV(position, absolute, relative, relative);
			right: 0;
			z-index: 2;
			@include cV(top, 18%, 15%, 0px);
			@include cV(right, 10%, 0, 0px);
			@include mq(min_max_w, 1025px, 1400px) {
				top: 30%;
			}
			@include mq(tab) {
				left: 5%;
				margin-bottom: 1.5em;
			}
			letter-spacing: 0.3em;
			@include mq(sp) {
				padding: 0px 1em;
				margin-bottom: 1em;
				letter-spacing: 0em;
			}
			.txt_title {
				@include fsz-cha(32, 30, 18);
				font-weight: 400;
				span {
					background: #fff;
					@include cV(padding, 3px 10px, 3px, 3px);
				}
			}
		}
		.box_info {
			@include cV(padding, 31px 0px, 31px 0px, 1em 0px);
			background: #fff;
			@include cV(position, absolute, relative, relative);
			@include cV(right, 0%, 0%, 0%);
			@include cV(bottom, 0px, -20px, 0px);
			z-index: 2;
			@include cV(width, wCon(800px, 1920px), 100%, 100%);
			@include mq(tab) {
				margin-top: 30px;
			}
			@include mq(sp) {
				padding-left: 1em;
				padding-right: 1em;
			}
			.box_info_sub {
				width: 630px;
				max-width: 100%;
				margin-left: auto;
				@include mq(tab) {
					width: 100%;
					padding: 0px 2em;
				}
				@include mq(sp) {
					margin: 0px !important;
				}
			}
			h4 {
				letter-spacing: 0.2em;
				@include cV(letter-spacing, 0.2em, 0);
				@include cV(margin-bottom, 20px, 20px, 1em);
				font-weight: 400;
				img {
					margin-right: 15px;
					vertical-align: bottom;
				}
			}
			.box_sub {
				@include cV(letter-spacing, 0.2em, 0);
				.txt_big20 {
					span {
						@include cV(margin-top, 7px, 7px, 7px);
						@include fsz(8,15);
						display: block;
					}
				}
			}
			.txt {
				@include cV(margin-top, 17px, 17px, 17px);
				@include cV(line-height, 2, 1.8, 1.5);
			}
			.txt_note {
				@include cV(margin-top, 10px, 10px, 10px);
				@include fsz(13);
			}
			.btn_basic {
				@include cV(margin-top, 26px, 26px, 1.5em);
			}
		}
		.box_slick {
			@include cV(width, wCon(1600px, 1920px), 100%, 100%);
			.slider-nav {
				@include cV(margin-top, -35px, -35px, -1em);
				@include cV(width, wCon(650px, 1600px), wCon(800px, 1600px), wCon(650px, 1600px));
				max-width: 95%;
				.img {
					@include cV(width, wCon(164px, 650px), wCon(160px, 650px), wCon(160px, 650px));
				}
			}
		}
	}
}

/* -----------------------------------------------------------
	slick-dots
----------------------------------------------------------- */
.slick-dots {
	display: flex !important;
	position: absolute;
	z-index: 2;
	right: 1.5em;
	bottom: 1.5em;
	li {
		button {
			border: none;
			background: #fff;
			color: transparent;
			width: 16px;
			height: 16px;
			margin-right: 10px;
		}
		&.slick-active {
			button {
				background: $c_blue;
			}
		}
	}
}